<template>
  <div class="error-wrapper">
    <div class="img-wrapper">
      <img src="@/assets/error/404.png" alt="" />
      <div class="back-to-login" @click="backToIndex">返回首页</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "notFound",
  methods: {
    backToIndex() {
      this.$router.push({ path: "/" });
    }
  }
};
</script>
<style lang="scss" scoped>
.error-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  background-color: #463070;
  .img-wrapper {
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
    .back-to-login {
      width: 140px;
      height: 45px;
      line-height: 45px;
      text-align: center;
      font-size: 14px;
      border-radius: 20px;
      color: #ffffff;
      margin: 0 auto;
      border: 1px solid #ffffff;
      cursor: pointer;
    }
  }
}
</style>
